import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, redirect, useLocation, useNavigate } from "react-router-dom";
import { getAllUsers } from "../pages/Users/Dependencies/action";
import { API_URL } from "../common/Utils/constant";
import axios from "axios";
// import ReactPaginate from "react-paginate";
const LeadTable = ({ leads }) => {

  const { users } = useSelector((state: any) => state.userReducer);
  const { authUserData } = useSelector((state: any) => state.authReducer);
  const navigate = useNavigate();
  const getLeadDetails = (data: any) => {
    if (authUserData.user_type === "ADMIN" || authUserData.user_type === "MANAGER") {
      return navigate(`/dashboard/orders/${data}`);
    } else {
      return navigate(`/dashboard/orders/employee/${data}`);
    }
  }

  const [fetchData, setFetchData] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  return (
    <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
      <div className="flex justify-between">
        <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
          My Leads
        </h4>
        {authUserData.user_type === "ADMIN" || authUserData.user_type === "MANAGER" ? <Link
          to="/dashboard/orders/add"
          className="mb-3 inline-flex items-center justify-center rounded-lg bg-primary text-center font-medium text-white hover:bg-opacity-90 px-4 py-1"
        >
          Add Lead
        </Link> : ""}

      </div>
      <div className="max-w-full overflow-x-auto">
        <table className="w-full table-auto">
          <thead>
            <tr className="bg-gray-2 text-left dark:bg-meta-4">
              <th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
                Order Number
              </th>
              <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                Lead Report Type
              </th>
              {/* <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                Address
              </th>  */}
              <th className="py-4 px-4 font-medium text-black dark:text-white">
                Lead Status
              </th>
              <th className="py-4 px-4 font-medium text-black dark:text-white">
                Start Time
              </th>
              <th className="py-4 px-4 font-medium text-black dark:text-white">
                Finish Time
              </th>
              <th className="py-4 px-4 font-medium text-black dark:text-white">
                Taken By
              </th>
              <th className="py-4 px-4 font-medium text-black dark:text-white">
                Generated By
              </th>

              <th className="py-4 px-4 font-medium text-black dark:text-white">
                Verified By
              </th>
              <th className="py-4 px-4 font-medium text-black dark:text-white">
                Create At
              </th>
            </tr>
          </thead>
          <tbody>
            {leads.length > 0 ?
              leads.map((d: any, index: any) => {
                console.log("tun");
                return <tr key={d.lead_id} onClick={() => getLeadDetails(d.lead_id)}>
                  <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                    <h5 className="font-medium text-black dark:text-white">
                      # {d.lead_order_number}
                    </h5>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <p className="text-black dark:text-white">{d.lead_report_type}</p>
                  </td>

                  {/* <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <p className="text-black dark:text-white"></p>
                  </td> */}
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <p className="inline-flex rounded-full bg-success bg-opacity-10 py-1 px-3 text-sm font-medium text-success">
                      {d.lead_status}
                    </p>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <p className="text-black dark:text-white">{d.lead_start_time}</p>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <p className="text-black dark:text-white">{d.lead_finish_time}</p>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <p className="text-black dark:text-white">{users.length > 0 && users.filter((data: any) => {
                      return data.user_id == d.lead_taken_by
                    })[0]?.user_name}</p>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <p className="text-black dark:text-white">{users.length > 0 && users.filter((data: any) => {
                      return data.user_id == d.lead_generated_by
                    })[0]?.user_name}</p>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <p className="text-black dark:text-white">{users.length > 0 && users.filter((data: any) => {
                      return data.user_id == d.lead_verified_by
                    })[0]?.user_name}</p>
                  </td>
                  <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                    <p className="text-black dark:text-white">{d.createdAt}</p>
                  </td>
                </tr>
              }) :
              <h1 className="font-bold text-xl text-center my-5 w-full">No Leads ...</h1>
            }

          </tbody>
        </table>
        {/* <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={({ selected }) => {
            setCurrentPage(selected - 1);
            setFetchData(!fetchData);
          }}
          pageCount={Math.ceil(count / 10)}
          previousLabel="<"
          renderOnZeroPageCount={null}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          className="flex items-center -space-x-px h-8 text-sm"
        /> */}
        <nav aria-label="Page navigation example" className="my-4">
          <ul className="flex items-center -space-x-px h-8 text-sm">
            <li>
              <a href="#" className="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-graydark hover:text-white dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                <span className="sr-only">Previous</span>
                <svg className="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 1 1 5l4 4" />
                </svg>
              </a>
            </li>
            <li>
              <a href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-graydark hover:text-white dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">1</a>
            </li>

            <li>
              <a href="#" className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-graydark hover:text-white dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                <span className="sr-only">Next</span>
                <svg className="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                </svg>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div >
  );
};

export default LeadTable;
