import axios from "axios";
import { API_URL } from "../../../common/Utils/constant";

export const uploadDropbox = async (currentFile, order_no, order_size) => {
    const formData = new FormData();
    formData.append('file', currentFile);
    try {
        const data = await axios.post(`${API_URL}/file/upload/${order_size}/${order_no}`, formData);
        return data;
    } catch (error) {
        console.error('Error uploading file:', error);
    }
}

export const listDropboxFiles = async (order_no, order_size) => {
    // formData.append('order_no', order_no);
    // formData.append('order_size', order_size);
    return axios.get(`${API_URL}/list/file/${order_size}/${order_no}`).then((res) => {
        console.log(res);
        return res.data;
    }).catch((error) => {
        console.log(error)
    })
}

export const donwloadDropboxFile = async (order_no, order_size, file_no) => {
    // formData.append('order_no', order_no);
    // formData.append('order_size', order_size);
    return axios.get(`${API_URL}/download/file/${order_size}/${order_no}/${file_no}`).then((res) => {
        console.log(res);
        return res.data;
    }).catch((error) => {
        console.log(error)
    })
}