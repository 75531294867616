export const addLeadInitialValue = {
    report_qty: "",
    report_address: "",
    report_customer_pitch: "",
    report_delivery: "",
    report_customer_notes: "",
    // report_images_taken_by: "",
    // report_employee_pitch: "",
    report_technical_team_note: "",
    // report_generated_report_size: "",
    // report_generated_by: "",
    // report_send: "",
    // report_auto_status: "",
    // report_ncm: "",
    report_cc_email_0: "",
    report_cc_email_1: "",
    report_default_cc: "",
    report_customer_note_for_email: "",
    report_insured_name: "",
    report_claim_number: "",
    report_customer_name: "",
    report_company_name: "",
    lead_report_type: "",
    lead_order_number: ""
    // lead_sub_type: "",
    // lead_status: "",
    // lead_taken_by: "",
    // lead_revise_by: "",
    // lead_verified_by: ""

}