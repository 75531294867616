import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { API_URL } from '../common/Utils/constant';
import axios from 'axios';
import { deactivateUser, getAllUsers } from '../pages/Users/Dependencies/action';

const UserTable = () => {
    const { users } = useSelector((state: any) => state.userReducer);
    const dispatch: any = useDispatch();
    const [reRender, setRender] = useState(false);
    const deleteUser = (user_id: number) => {
        console.log(user_id)
        axios.delete(`${API_URL}/user/${user_id}`, {
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("skyoffsite_token")}`,
            },
        }).then((res: any) => {
            console.log(res.data.data)
            if (res.data) {
                window.location.reload();
            }
        })
    }

    const navigate = useNavigate();
    const userEditMode = (d) => {
        return navigate(`/dashboard/users/edit/${d.user_id}`);
    }

    const { authUserData } = useSelector((state: any) => state.authReducer);
    return (
        <>
            <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
                <div className='flex justify-between'>
                    <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
                        Users
                    </h4>

                    <Link
                        to="/dashboard/users/add"
                        className="inline-flex items-center justify-center rounded-lg bg-primary text-center font-medium text-white hover:bg-opacity-90 px-4 py-1"
                    >
                        Add User
                    </Link>
                </div>
                <div className="max-w-full overflow-x-auto">
                    <table className="w-full table-auto">
                        <thead>
                            <tr className="bg-gray-2 text-left dark:bg-meta-4">
                                <th className="min-w-[220px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
                                    Name
                                </th>
                                <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                                    Email
                                </th>
                                <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                                    Mobile
                                </th>
                                <th className="py-4 px-4 font-medium text-black dark:text-white">
                                    Type
                                </th>
                                <th className="py-4 px-4 font-medium text-black dark:text-white">
                                    Status
                                </th>
                                <th className="py-4 px-4 font-medium text-black dark:text-white">
                                    Create At
                                </th>
                                <th className="py-4 px-4 font-medium text-black dark:text-white">
                                    A/D
                                </th>
                                <th className="py-4 px-4 font-medium text-black dark:text-white">
                                    Delete User
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.length > 0 &&
                                users.map((d: any, index: any) => {
                                    return <tr key={index}>
                                        <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                                            <h5 className="font-medium text-black dark:text-white">
                                                {d.user_name}
                                            </h5>
                                        </td>
                                        <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark cursor-pointer" onClick={() => userEditMode(d)}>
                                            <p className="text-black dark:text-white hover:text-danger">{d.user_email}</p>
                                        </td>
                                        <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                            <p className="text-black dark:text-white">{d.user_mobile}</p>
                                        </td>
                                        <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                            <p className="inline-flex rounded-full bg-success bg-opacity-10 py-1 px-3 text-sm font-medium text-success">
                                                {d.user_type}
                                            </p>
                                        </td>
                                        <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                            <p className={`inline-flex rounded-full ${d.user_disable ? "bg-danger" : "bg-success"} bg-opacity-10 py-1 px-3 text-sm font-medium ${d.user_disable ? "text-danger" : "text-success"} `}>
                                                {d.user_disable ? "Deactivate" : "Activate"}
                                            </p>
                                        </td>
                                        <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                            <p className="text-black dark:text-white">{d.createdAt}</p>
                                        </td>
                                        {/* <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                            <p className="text-black dark:text-white">{d.updatedAt}</p>
                                        </td> */}
                                        <td className='text-center'>
                                            <button className="hover:text-primary text-danger" onClick={async (e) => {
                                                e.preventDefault();
                                                const data = window.confirm(`Do you want to ${!d.user_disable ? "deactivate" : "activate"} ${d.user_name} user`);
                                                if (data) {
                                                    const deactivate = await deactivateUser(d.user_id);
                                                    // navigate('/users');
                                                    if (deactivate) {
                                                        // dispatch(getAllUsers());
                                                        //     setRender(!reRender);
                                                        window.location.reload();
                                                    }
                                                }
                                            }}>
                                                {!d.user_disable ? <svg
                                                    className="fill-current"
                                                    width="18"
                                                    height="18"
                                                    viewBox="0 0 18 18"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M13.7535 2.47502H11.5879V1.9969C11.5879 1.15315 10.9129 0.478149 10.0691 0.478149H7.90352C7.05977 0.478149 6.38477 1.15315 6.38477 1.9969V2.47502H4.21914C3.40352 2.47502 2.72852 3.15002 2.72852 3.96565V4.8094C2.72852 5.42815 3.09414 5.9344 3.62852 6.1594L4.07852 15.4688C4.13477 16.6219 5.09102 17.5219 6.24414 17.5219H11.7004C12.8535 17.5219 13.8098 16.6219 13.866 15.4688L14.3441 6.13127C14.8785 5.90627 15.2441 5.3719 15.2441 4.78127V3.93752C15.2441 3.15002 14.5691 2.47502 13.7535 2.47502ZM7.67852 1.9969C7.67852 1.85627 7.79102 1.74377 7.93164 1.74377H10.0973C10.2379 1.74377 10.3504 1.85627 10.3504 1.9969V2.47502H7.70664V1.9969H7.67852ZM4.02227 3.96565C4.02227 3.85315 4.10664 3.74065 4.24727 3.74065H13.7535C13.866 3.74065 13.9785 3.82502 13.9785 3.96565V4.8094C13.9785 4.9219 13.8941 5.0344 13.7535 5.0344H4.24727C4.13477 5.0344 4.02227 4.95002 4.02227 4.8094V3.96565ZM11.7285 16.2563H6.27227C5.79414 16.2563 5.40039 15.8906 5.37227 15.3844L4.95039 6.2719H13.0785L12.6566 15.3844C12.6004 15.8625 12.2066 16.2563 11.7285 16.2563Z"
                                                        fill=""
                                                    />
                                                    <path
                                                        d="M9.00039 9.11255C8.66289 9.11255 8.35352 9.3938 8.35352 9.75942V13.3313C8.35352 13.6688 8.63477 13.9782 9.00039 13.9782C9.33789 13.9782 9.64727 13.6969 9.64727 13.3313V9.75942C9.64727 9.3938 9.33789 9.11255 9.00039 9.11255Z"
                                                        fill=""
                                                    />
                                                    <path
                                                        d="M11.2502 9.67504C10.8846 9.64692 10.6033 9.90004 10.5752 10.2657L10.4064 12.7407C10.3783 13.0782 10.6314 13.3875 10.9971 13.4157C11.0252 13.4157 11.0252 13.4157 11.0533 13.4157C11.3908 13.4157 11.6721 13.1625 11.6721 12.825L11.8408 10.35C11.8408 9.98442 11.5877 9.70317 11.2502 9.67504Z"
                                                        fill=""
                                                    />
                                                    <path
                                                        d="M6.72245 9.67504C6.38495 9.70317 6.1037 10.0125 6.13182 10.35L6.3287 12.825C6.35683 13.1625 6.63808 13.4157 6.94745 13.4157C6.97558 13.4157 6.97558 13.4157 7.0037 13.4157C7.3412 13.3875 7.62245 13.0782 7.59433 12.7407L7.39745 10.2657C7.39745 9.90004 7.08808 9.64692 6.72245 9.67504Z"
                                                        fill=""
                                                    />
                                                </svg> : <svg className='w-7' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="power-off"><path fill="#549bff" d="m12,13.75c-.414,0-.75-.336-.75-.75V3c0-.414.336-.75.75-.75s.75.336.75.75v10c0,.414-.336.75-.75.75Z"></path><path fill="#112d55" d="m12,21.75c-4.825,0-8.75-3.925-8.75-8.75,0-3.122,1.677-6.022,4.377-7.571.359-.208.817-.081,1.023.278.206.359.082.818-.277,1.024-2.234,1.281-3.623,3.683-3.623,6.269,0,3.998,3.252,7.25,7.25,7.25s7.25-3.252,7.25-7.25c0-2.586-1.389-4.988-3.623-6.269-.359-.206-.483-.665-.277-1.024.206-.358.663-.486,1.023-.278,2.7,1.548,4.377,4.449,4.377,7.571,0,4.825-3.925,8.75-8.75,8.75Z"></path></svg>}</button>


                                        </td>
                                        <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                            <button className="inline-flex items-center cursor-pointer justify-center rounded-lg bg-danger text-center font-medium text-white hover:bg-opacity-90 py-1 px-4"
                                                onClick={() => {
                                                    deleteUser(d.user_id)
                                                }}
                                            >
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                })

                            }

                        </tbody>
                    </table>
                </div>
            </div >
        </>
    )
}

export default UserTable