import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsers } from '../pages/Users/Dependencies/action';
import { startWorking, stopWorking } from '../pages/MyLead/Dependencies/action';
import ReportUpload from './ReportUpload/ReportUpload';
import moment from 'moment';

const DetailsCard = ({ details }) => {

    const { users } = useSelector((state: any) => state.userReducer);
    // const dispatch = useDispatch();
    // const verified_by = "";
    // const generated_by = "";
    // const taken_by = "";
    // const image_taken_by = "";
    // console.log(details)
    // useEffect(() => {
    //     dispatch(getAllUsers() as any);
    // }, [])
    console.log(details)
    return (<>
        {users.length > 0 && <div className="bg-white p-3 shadow-sm rounded-sm">
            <div className="flex justify-between items-center space-x-2 font-semibold text-gray-900 leading-8">
                <div className="flex justify-between items-center space-x-2 font-semibold text-gray-900 leading-8">
                    <span className="text-green-500">
                        <svg className="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                        </svg>
                    </span>
                    <span className="tracking-wide">Order Details</span>
                </div>
                <div>
                    {!details.lead_start_time ?
                        <button className="rounded bg-success p-3 font-medium text-gray" type="button" onClick={async () => {
                            const data = await startWorking(details.lead_id);
                            if (data) {
                                window.location.reload();
                            }
                        }}>
                            Start Lead
                        </button>
                        : details.lead_status === "Done" ?
                            <span className='font-medium'></span>
                            :
                            <span className='font-medium mx-2'>Working on Progress</span>
                    }
                    {details.lead_start_time && details.lead_status === "Pending" &&
                        <button className="rounded bg-danger p-3 font-medium text-gray mr-3" type="button" onClick={async () => {
                            const data = await stopWorking(details.lead_id);
                            if (data) {
                                window.location.reload();
                            }
                        }}>
                            Clear Time Log
                        </button>

                    }
                </div>
            </div>
            <div className="text-gray-700">
                <div className="grid md:grid-cols-2 text-sm">
                    <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">Order Number</div>
                        <div className="px-4 py-2">{details.lead_order_number}</div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">Order Report Size</div>
                        <div className="px-4 py-2">{details.lead_report_type}</div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">Quantity</div>
                        <div className="px-4 py-2">{details.report_qty}</div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">Address</div>
                        <div className="px-4 py-2">{details.report_address}</div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">Main Roof Pitch</div>
                        <div className="px-4 py-2">{details.report_customer_pitch}</div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">Delivery Priority</div>
                        <div className="px-4 py-2">{details.report_delivery}</div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">Customer Notes</div>
                        <div className="px-4 py-2">
                            <a className="text-blue-800">{details.report_customer_notes}</a>
                        </div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">Images Taken By</div>
                        <div className="px-4 py-2">{users.filter((d) => {
                            return d.user_id == details.report_images_taken_by
                        })[0]?.user_name}</div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">Area Main Roof</div>
                        <div className="px-4 py-2">{details.report_employee_pitch}</div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">Technical Team Note</div>
                        <div className="px-4 py-2">{details.report_technical_team_note}</div>
                    </div>

                    <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">Made By/Revise By</div>
                        <div className="px-4 py-2">{users.filter((d: any) => {
                            return d.user_id == details.lead_taken_by
                        })[0]?.user_name}</div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">Status</div>
                        <div className="px-4 py-2">{details.lead_status}</div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">Verified By</div>
                        <div className="px-4 py-2">{
                            users.filter((d: any) => {
                                return d.user_id == details.lead_verified_by
                            })[0]?.user_name
                        }</div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">Generate Report Size</div>
                        <div className="px-4 py-2">{details.report_generated_report_size}</div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">Generated By</div>
                        <div className="px-4 py-2">{users.filter((d: any) => {
                            return d.user_id == details.lead_generated_by
                        })[0]?.user_name}</div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">Send/Generate/NCM</div>
                        <div className="px-4 py-2">{details.report_send}</div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">Auto Email/Order Status</div>
                        <div className="px-4 py-2">{details.report_auto_status}</div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">Generated/NCM/Error</div>
                        <div className="px-4 py-2">{details.report_auto_status}</div>
                    </div>

                    <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">Start Time</div>
                        <div className="px-4 py-2">{details.lead_start_time && moment(details.lead_start_time).format('h:mm a')}</div>
                    </div>

                    <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">End Time</div>
                        <div className="px-4 py-2">{details.lead_finish_time && moment(details.lead_finish_time).format('h:mm a')}</div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">CC-Email Id 0</div>
                        <div className="px-4 py-2">{details.report_cc_email_0}</div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">CC-Email Id 1</div>
                        <div className="px-4 py-2">{details.report_cc_email_1}</div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">Default CC</div>
                        <div className="px-4 py-2">{details.report_default_cc}</div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">Customer Note for Email</div>
                        <div className="px-4 py-2">{details.report_customer_note_for_email}</div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">Insured Name</div>
                        <div className="px-4 py-2">{details.report_insured_name}</div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">Claim Number</div>
                        <div className="px-4 py-2">{details.report_claim_number}</div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">Customer Name</div>
                        <div className="px-4 py-2">{details.report_customer_name}</div>
                    </div>

                    <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">Company Name</div>
                        <div className="px-4 py-2">{details.report_company_name}</div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">Report Generation Status</div>
                        <div className="px-4 py-2">{details.lead_generated_status}</div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">Report Verified Status</div>
                        <div className="px-4 py-2">{details.lead_verified_status}</div>
                    </div>
                </div>
                {(details.lead_report_type && details.lead_order_number) && <div className="grid md:grid-cols-1 text-sm">
                    <div className="px-4 py-2 font-semibold">Upload Report:</div>
                    <ReportUpload report_type={details.lead_report_type} order_number={details.lead_order_number} />
                </div>}

            </div>
        </div>}
    </>
    )
}

export default DetailsCard