import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { getUserOrders } from './Dependencies/action';
import LeadTable from '../../components/LeadTable';

const ManageUserOrders = () => {
    const [leads, setLeads] = useState([]);
    const { users } = useSelector((state: any) => state.userReducer);
    return (
        <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
            <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
                <h3 className="font-medium text-black dark:text-white">
                    Assign Order
                </h3>
            </div>
            <Formik
                initialValues={{ lead_user_id: "" }}
                onSubmit={async (values) => {
                    const data = await getUserOrders(values.lead_user_id);
                    if (data) {
                        console.log(data.data)
                        setLeads(data.data)
                    }
                }}
            >
                {(props: FormikProps<any>) => (
                    <Form>
                        <div className="p-6.5">
                            {/* 1st Row */}
                            <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                                <div className="w-full xl:w-1/3">
                                    <label className="mb-2.5 block text-black dark:text-white">
                                        Select User Name
                                    </label>
                                    <div className='flex'>
                                        <Field as="select" type="text" name="lead_user_id" className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary" placeholder="Enter your Full Name">
                                            <option value="" defaultChecked>-----</option>
                                            {users.length > 0 && users.map((user) => {
                                                return <option value={user.user_id}>{user.user_name}</option>
                                            })}
                                        </Field>
                                        <input
                                            type="submit"
                                            className="mx-5 cursor-pointer inline-flex items-center justify-center rounded-lg bg-primary text-center font-medium text-white hover:bg-opacity-90 px-4 py-1"
                                            value="Submit" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            <LeadTable leads={leads} />
        </div>
    )
}

export default ManageUserOrders