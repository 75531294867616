import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import LogsTable from '../../components/LogsTable';
import { getAllLogs } from './Dependencies/action';

const UserLogs = () => {

    const [logs, setLogs] = useState([]);

    useEffect(() => {
        getAllLogs().then((data) => {
            if (data) {
                console.log(data.data)
                setLogs(data.data)
            }
        });
    }, [])

    return (
        <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
            <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
                <h3 className="font-medium text-black dark:text-white">
                    User Logs
                </h3>
            </div>

            <LogsTable logs={logs} />
        </div>
    )
}

export default UserLogs