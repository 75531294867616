export function authReducer(state = {
    authUserData: {},
}, action) {
    switch (action.type) {
        case 'AUTH_DATA': {
            return {
                ...state,
                authUserData: action.data,
            };
        }

        default: {
            return state;
        }
    }
}