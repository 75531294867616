import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useEffect } from 'react'
import { setLeadEmployeeReport } from '../pages/Dashboard/Dependencies/action'
import { useDispatch, useSelector } from 'react-redux';

const EmployeeReport = () => {

    const dispatch: any = useDispatch();

    useEffect(() => {
        dispatch(setLeadEmployeeReport())
    }, [])


    const { lead_employee_report } = useSelector((state: any) => state.leadReportReducer);
    return (<>

        <h1 className='font-bold text-center text-2xl my-10 underline'>Work Status Board</h1>
        <TableContainer component={Paper} className='mt-5'>
            <Table className='w-full table-auto'>
                <TableHead>
                    <TableRow className='bg-gray-2 text-left dark:bg-meta-4'>
                        <TableCell className="min-w-[120px] py-4 px-4 font-bold text-black dark:text-white">
                            Name
                        </TableCell>
                        <TableCell className="min-w-[180px] py-4 px-4 font-bold text-black dark:text-white xl:pl-11">
                            Current Made By
                        </TableCell>
                        <TableCell className="py-4 px-4 font-bold text-black dark:text-white xl:pl-11">
                            Current Verified By
                        </TableCell>
                        <TableCell className="py-4 px-4 font-bold text-black dark:text-white">
                            Current Generated By
                        </TableCell>
                        {/* <TableCell className="py-4 px-4 font-bold text-black dark:text-white">
                            Current Image Taken By
                        </TableCell> */}

                    </TableRow>
                </TableHead>
                <TableBody>
                    {lead_employee_report
                        .map((user_report, id) => (
                            <TableRow key={id}>
                                <TableCell className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                                    <h5 className="font-medium text-black dark:text-white">
                                        {user_report.user_name}
                                    </h5>
                                </TableCell>
                                <TableCell className="border-b border-[#eee] cursor-pointer py-5 px-4 pl-9 dark:border-strokedark xl:pl-11" >
                                    <h5 className="font-medium text-black hover:text-[#e1ad01] dark:text-white">
                                        <p className="text-black dark:text-white"> {user_report.made_by}</p>
                                    </h5>
                                </TableCell>
                                <TableCell className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                    <p className="text-black dark:text-white"> {user_report.generated_by}</p>
                                </TableCell>

                                <TableCell className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                    <p className="text-black dark:text-white"> {user_report.verified_by}</p>
                                </TableCell>
                                {/* <TableCell className={`border-b border-[#eee] py-5 px-4 dark:border-strokedark`}>
                                    <p className="text-black dark:text-white">{user_report.image_taken_by}</p>
                                </TableCell> */}
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    </>
    )
}

export default EmployeeReport