import React, { useEffect, useState } from 'react'
import DetailsCard from '../../components/DetailsCard'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios';
import { API_URL } from '../../common/Utils/constant';
import { getOneLeadData, setLeadWorkChange } from './Dependencies/action';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik, FormikProps } from 'formik';

const LeadDetails = () => {
    const { id } = useParams();
    const [details, setDetails] = useState({ lead_status: "" });
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getOneLeadData(id) as any).then((data) => {
            setDetails(data)
        });
        // dispatch(getAllUsers() as any)
        // dispatch(getLoginUserDetails() as any)
    }, [])
    const { users } = useSelector((state: any) => state.userReducer);
    const sendToCustomer = (e) => {
        e.preventDefault();
    }

    const { product_list } = useSelector((state: any) => state.leadData)
    return (
        <>
            {details ?
                <DetailsCard details={details} />
                : "No Record Found"}
            {/* <Link
                to={`/dashboard/orders/edit/${id}`}
                className="my-5 inline-flex items-center justify-center rounded-lg bg-primary text-center font-medium text-white hover:bg-opacity-90 px-4 py-1"
            >
                Edit Lead
            </Link> */}
            {users.length > 0 && <div className="flex flex-col gap-9">
                {/* <!-- Contact Form --> */}
                <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                    <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
                        <h3 className="font-medium text-black dark:text-white">
                            Assign Order
                        </h3>
                    </div>
                    <Formik
                        initialValues={{ none_data: "" }}
                        onSubmit={async (values) => {
                            const data = await setLeadWorkChange(values, id);
                            if (data) {
                                // alert(data);
                                window.location.reload();
                            }
                        }}
                    >
                        {(props: FormikProps<any>) => (
                            <Form>
                                <div className="p-6.5">
                                    {/* 1st Row */}
                                    <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                                        <div className="w-full xl:w-1/3">
                                            <label className="mb-2.5 block text-black dark:text-white">
                                                Made By/Revised By
                                            </label>
                                            <Field as="select" type="text" name="lead_taken_by" className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary" placeholder="Enter your Full Name">
                                                <option value="" defaultChecked>-----</option>
                                                {users.length > 0 && users.map((user) => {
                                                    return <option value={user.user_id}>{user.user_name}</option>
                                                })}
                                            </Field>
                                        </div>
                                        <div className="w-full xl:w-1/3">
                                            <label className="mb-2.5 block text-black dark:text-white">
                                                Generated By
                                            </label>
                                            <Field as="select" type="text" name="lead_generated_by" className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary" placeholder="Enter your Full Name">
                                                <option value="" defaultChecked>-----</option>
                                                {users.length > 0 && users.map((user) => {
                                                    return <option value={user.user_id}>{user.user_name}</option>
                                                })}
                                            </Field>
                                        </div>
                                        <div className="w-full xl:w-1/3">
                                            <label className="mb-2.5 block text-black dark:text-white">
                                                Verified By
                                            </label>
                                            <Field as="select" type="text" name="lead_verified_by" className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary" placeholder="Enter your Full Name">
                                                <option value="" defaultChecked>-----</option>
                                                {users.length > 0 && users.map((user) => {
                                                    return <option value={user.user_id}>{user.user_name}</option>
                                                })}
                                            </Field>
                                        </div>

                                        <div className="w-full xl:w-1/3">
                                            <label className="mb-2.5 block text-black dark:text-white">
                                                Images Taken By
                                            </label>
                                            <Field as="select" type="text" name="report_images_taken_by" className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary" placeholder="Enter your Full Name">
                                                <option value="" defaultChecked>-----</option>
                                                {users.length > 0 && users.map((user) => {
                                                    return <option value={user.user_id}>{user.user_name}</option>
                                                })}
                                            </Field>
                                        </div>
                                        <div className="w-full xl:w-1/3">
                                            <label className="mb-2.5 block text-black dark:text-white">
                                                Status
                                            </label>
                                            <Field as="select" type="text" name="lead_status" className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary" placeholder="Enter your Full Name">
                                                <option value="" defaultChecked>-----</option>
                                                <option value="Pending">Pending</option>
                                                <option value="Done">Done</option>
                                                <option value="Cencelled">Cencelled</option>
                                                <option value="Repeat cell">Repeat cell</option>
                                                <option value="Duplicate Qty">Duplicate Qty</option>
                                            </Field>
                                        </div>
                                        <div className="w-full xl:w-1/3">
                                            <label className="mb-2.5 block text-black dark:text-white">
                                                Generation Status
                                            </label>
                                            <Field as="select" type="text" name="lead_generated_status" className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary" placeholder="Enter your Full Name">
                                                <option value="  " defaultChecked>-----</option>
                                                <option value="Pending">Pending</option>
                                                <option value="Done">Done</option>
                                            </Field>
                                        </div>
                                        <div className="w-full xl:w-1/3">
                                            <label className="mb-2.5 block text-black dark:text-white">
                                                Generated Report Size
                                            </label>
                                            {/* <Field type="text" name="" className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary" placeholder="Enter your Full Name" /> */}
                                            <Field type="text" as="select" name="report_generated_report_size" className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary" placeholder="Enter your Full Name">
                                                <option value="" defaultChecked>-----</option>
                                                {product_list.map((d) => {
                                                    return <option value={d}>{d}</option>
                                                })}
                                            </Field>
                                        </div>
                                    </div>

                                    <div className='mb-4.5 flex flex-col gap-6 xl:flex-row'>
                                        <div className="w-full xl:w-1/3">
                                            <label className="mb-2.5 block text-black dark:text-white">
                                                Insured Name
                                            </label>
                                            <Field type="text" name="report_insured_name" className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary" placeholder="Enter your Full Name" />
                                        </div>

                                        <div className="w-full xl:w-1/3">
                                            <label className="mb-2.5 block text-black dark:text-white">
                                                Claim Name
                                            </label>
                                            <Field type="text" name="report_claim_number" className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary" placeholder="Enter your Full Name" />
                                        </div>
                                        <div className="w-full xl:w-1/3">
                                            <label className="mb-2.5 block text-black dark:text-white">
                                                Area main Roof/Predominant Pitch
                                            </label>
                                            <Field type="text" name="report_employee_pitch" className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary" placeholder="Enter your Full Name" />
                                        </div>

                                    </div>

                                    <div className='mb-4.5 flex flex-col gap-6 xl:flex-row'>
                                        <div className="w-full xl:w-1/3">
                                            <label className="mb-2.5 block text-black dark:text-white">
                                                Technical Team Notes
                                            </label>
                                            <Field type="text" as="textarea" name="report_technical_team_note" className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary" placeholder="Enter your Technical Team Notes" />
                                        </div>

                                        <div className="w-full xl:w-1/3">
                                            <label className="mb-2.5 block text-black dark:text-white">
                                                CC Email Id 0
                                            </label>
                                            <Field type="text" as="textarea" name="report_cc_email_0" className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary" placeholder="Enter your Full Name" />
                                        </div>

                                        <div className="w-full xl:w-1/3">
                                            <label className="mb-2.5 block text-black dark:text-white">
                                                CC Email Id 1
                                            </label>
                                            <Field type="text" as="textarea" name="report_cc_email_1" className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary" placeholder="Enter your Full Name" />
                                        </div>

                                    </div>
                                    <div className='mb-4.5 flex flex-col gap-6 xl:flex-row'>
                                        <div className="w-full xl:w-1/3">
                                            <label className="mb-2.5 block text-black dark:text-white">
                                                Customer Note For Email
                                            </label>
                                            <Field type="text" as="textarea" name="report_customer_note_for_email" className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary" placeholder="Customer Note For Email" />

                                        </div>

                                        <div className="w-full xl:w-1/3">
                                            <label className="mb-2.5 block text-black dark:text-white">
                                                Address
                                            </label>
                                            <Field type="text" as="textarea" name="report_address" className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary" placeholder="Customer Note For Email" />

                                        </div>

                                        <div className="w-full xl:w-1/3">
                                            <label className="mb-2.5 block text-black dark:text-white">
                                                Default CC
                                            </label>
                                            <Field type="text" as="textarea" name="report_default_cc" className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary" placeholder="Customer Note For Email" />

                                        </div>

                                        <div className="w-full xl:w-1/3">
                                            <label className="mb-2.5 block text-black dark:text-white">
                                                Report Send Status
                                            </label>
                                            <Field as="select" type="text" name="lead_verified_status" className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary" placeholder="Enter your Full Name">
                                                <option value="  " defaultChecked>-----</option>
                                                <option value="Pending">Pending</option>
                                                <option value="Done">Done</option>
                                                <option value="Cencelled">Cencelled</option>
                                                <option value="Repeat cell">Repeat cell</option>
                                                <option value="Duplicate Qty">Duplicate Qty</option>
                                            </Field>
                                        </div> 
                                    </div>
                                    <div className='flex justify-between'>
                                        <input
                                            type="submit"
                                            className="my-5 inline-flex items-center justify-center rounded-lg bg-primary text-center font-medium text-white hover:bg-opacity-90 px-4 py-1"
                                            value="Send To Team" />
                                        {/* {(details.lead_status === "Done" && authUserData.user_id == details.lead_verified_by) && <input
       type="button"
       className="my-5 inline-flex items-center justify-center rounded-lg bg-danger text-center font-medium text-white hover:bg-opacity-90 px-4 py-1"
       value="Send To Customer" onClick={sendToCustomer} />} */}

                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
            }

        </>
    )
}

export default LeadDetails