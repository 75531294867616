import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const Protected = ({ access, children }) => {
    const { authUserData } = useSelector((state: any) => state.authReducer)
    const navigate = useNavigate();
    useEffect(() => {
        console.log(access, authUserData.user_type, !access.includes(authUserData.user_type))
        if (!access.includes(authUserData.user_type)) {
            // alert("You Can't Not Access This Route");
            return navigate('/dashboard');
        }
    })
    return children
}

export default Protected;