import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../Users/Dependencies/action";
import { deleteAssign, editAssignOrders, getAllAssign, getProductList, insertAssignOrders } from "../Dependencies/action";
import RobinHoodOrderAssign from "../../../components/RobinHoodOrderAssign";
import { truncate } from "fs";

const AssignOrders = () => {
  const dispatch: any = useDispatch();
  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getProductList());
    dispatch(getAllAssign());
  }, [])

  const { authUserData } = useSelector((state: any) => state.authReducer)
  const { assign_list } = useSelector((state: any) => state.userReducer)
  const [assignDefault, setAssignDefault] = useState([]);
  useEffect(() => {
    const dat = assign_list.filter(d => d.assign_default == 'LIVE');

    const dj = assign_list.filter(d => d.assign_default == 'DEFAULT');
    setAssignCron(dat);
    setAssignDefault(dj);
    console.log('running')
  }, [assign_list])


  const selectDataAssign = async (user, product, operation, assign_id, format, lastUser, assign_default) => {
    console.log(format)
    if (assign_id == 0) {
      const payload = {
        user_id: authUserData.user_id,
        user_list: JSON.stringify(user.map(d => d.value)),
        product_list: JSON.stringify(product.map(d => d.value)),
        operation: operation.value,
        format: JSON.stringify(format.map(d => d.value)),
        user_assign_order: lastUser.value,
        assign_default: assign_default ? 'DEFAULT' : 'LIVE'
      }
      const data = await insertAssignOrders(payload);
      if (data) {
        dispatch(getAllAssign());
      }

    } else if (operation) {
      const payload = {
        user_id: authUserData.user_id,
        user_list: JSON.stringify(user.map(d => d.value)),
        product_list: JSON.stringify(product.map(d => d.value)),
        operation: operation.value,
        format: JSON.stringify(format.map(d => d.value)),
        user_assign_order: lastUser.value
      }
      const data = await editAssignOrders(payload, assign_id);
      if (data) {
        dispatch(getAllAssign());
      }
      alert("Assign Order Updated Madam ji!!")
    } else {
      if (assign_id) {
        const data_await = await deleteAssign(assign_id);
        if (data_await) {
          dispatch(getAllAssign());
        }
      }
    }
  }
  const { users } = useSelector((state: any) => state.userReducer);
  const { product_list } = useSelector((state: any) => state.leadData);
  const [multiOptions, setMultiOptions] = useState([]);
  const [multiOptionsProduct, setMultiOptionsProduct] = useState([]);
  const [assignCron, setAssignCron] = useState([]);

  useEffect(() => {
    const result = users.length > 0 && users.map(({ user_id, user_name }) => {
      return {
        label: user_name,
        value: user_id
      }
    })
    setMultiOptions(result);
  }, [users]);

  useEffect(() => {
    const result = product_list.map((d: any) => {
      return { label: d, value: d }
    })
    setMultiOptionsProduct(result);
  }, [product_list]);

  return (
    <>
      {assignCron.map((data, index) => {
        return <div key={data.assign_id}><RobinHoodOrderAssign defaultAssign={false} multiOptions={multiOptions} multiOptionsProduct={multiOptionsProduct} takeSelectedData={selectDataAssign} label={"Made By " + (index + 1)} AssignData={data} /></div>
      })}
      <button className="flex justify-center rounded bg-secondary p-3 mt-3 font-medium text-gray" type="button" onClick={() => {
        setAssignCron([...assignCron, {}])
      }}>
        Start Assigning
      </button>

      <h1 className="text-2xl mt-5 underline font-bold">Default Assigning Template</h1>
      {assignDefault.map((data, index) => {
        return <div key={data.assign_id}><RobinHoodOrderAssign defaultAssign={true} multiOptions={multiOptions} multiOptionsProduct={multiOptionsProduct} takeSelectedData={selectDataAssign} label={"Made By " + (index + 1)} AssignData={data} /></div>
      })}
      <button className="flex justify-center rounded bg-secondary p-3 mt-3 font-medium text-gray" type="button" onClick={() => {
        setAssignDefault([...assignDefault, {}])
      }}>
        Add Default Assigning Template
      </button>
      {/* <RobinHoodOrderAssign multiOptions={multiOptions} multiOptionsProduct={multiOptionsProduct} takeSelectedData={selectDataAssign} label="Verified By" />

      <RobinHoodOrderAssign multiOptions={multiOptions} multiOptionsProduct={multiOptionsProduct} takeSelectedData={selectDataAssign} label="Generated By" /> */}
    </>
  )
}

export default AssignOrders