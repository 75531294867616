export const employeeEditLeadInitialValue = (details) => {
    console.log("details_enployee", details)
    return {
        report_qty: details?.report_qty ? details.report_qty : "",
        report_address: details?.report_address ? details.report_address : "",
        report_customer_pitch: details?.report_customer_pitch ? details.report_customer_pitch : "",
        report_delivery: details?.report_delivery ? details.report_delivery : "",
        report_customer_notes: details?.report_customer_notes ? details.report_customer_notes : "",
        report_images_taken_by: details?.report_images_taken_by ? details.report_images_taken_by : "",
        report_employee_pitch: details?.report_employee_pitch ? details.report_employee_pitch : "",
        report_technical_team_note: details?.report_technical_team_note ? details.report_technical_team_note : "",
        report_generated_report_size: details?.report_generated_report_size ? details.report_generated_report_size : "",
        lead_generated_by: details?.lead_generated_by ? details.lead_generated_by : "",
        report_send: details?.report_send ? details.report_send : "",
        report_auto_status: details?.report_auto_status ? details.report_auto_status : "",
        report_ncm: details?.report_ncm ? details.report_ncm : "",
        report_cc_email_0: details?.report_cc_email_0 ? details.report_cc_email_0 : "",
        report_cc_email_1: details?.report_cc_email_1 ? details.report_cc_email_1 : "",
        report_default_cc: details?.report_default_cc ? details.report_default_cc : "",
        report_customer_note_for_email: details?.report_customer_note_for_email ? details.report_customer_note_for_email : "",
        report_insured_name: details?.report_insured_name ? details.report_insured_name : "",
        report_claim_number: details?.report_claim_number ? details.report_claim_number : "",
        report_customer_name: details?.report_customer_name ? details.report_customer_name : "",
        report_company_name: details?.report_company_name ? details.report_company_name : "",
        lead_report_type: details?.lead_report_type ? details.lead_report_type : "",
        lead_order_number: details?.lead_order_number ? details.lead_order_number : "",
        // lead_sub_type: details?.lead_sub_type ? details.lead_sub_type : "",
        lead_status: details?.lead_status ? details.lead_status : "",
        lead_taken_by: details?.lead_taken_by ? details.lead_taken_by : "",
        // lead_revise_by: details?.lead_revise_by ? details.lead_revise_by : "",
        lead_verified_by: details?.lead_verified_by ? details.lead_verified_by : "",
        lead_verified_status: details?.lead_verified_status ? details.lead_verified_status : "",

    }
}