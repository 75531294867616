//otpInputs.js
import React, { useState } from "react";
import { AuthVerifyOtp, getLoginUserDetails } from "./Dependencies/action";
import { useDispatch } from "react-redux";
import { getAllUsers } from "../Users/Dependencies/action";
import { getProductList } from "../MyLead/Dependencies/action";
import { useNavigate } from "react-router-dom";

//Our parent component
const OTPInputGroup = ({ session_id, isHandsUpInput }) => {
    //state to store all input boxes    
    const [inputValues, setInputValues] = useState({
        input1: '',
        input2: '',
        input3: '',
        input4: ''
        // Add more input values here
    });
    //this function updates the value of the state inputValues
    const handleInputChange = (inputId, value) => {
        isHandsUpInput!.value = true
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [inputId]: value,
        }));
    };
    //this function processes form submission
    // verifyOtpSubmit(inputValues);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleSubmit = async () => {
        // ... Your submit logic here
        console.log(inputValues, session_id)
        const otp = `${inputValues.input1}${inputValues.input2}${inputValues.input3}${inputValues.input4}`
        const response = await dispatch(AuthVerifyOtp({ session_id, otp }) as any)
        if (response) {
            dispatch(getAllUsers() as any);
            dispatch(getLoginUserDetails() as any);
            dispatch(getProductList() as any);
            navigate('/dashboard');
        }
    };
    //return child component
    return (
        <>
            <div id='OTPInputGroup' className="flex flex-row items-center justify-between mx-auto w-full max-w-xs" data-autosubmit="true">
                <OTPInput
                    id="input1"
                    value={inputValues.input1}
                    onValueChange={handleInputChange}
                    previousId={null}
                    handleSubmit={handleSubmit}
                    nextId="input2"
                />
                <OTPInput
                    id="input2"
                    value={inputValues.input2}
                    onValueChange={handleInputChange}
                    previousId="input1"
                    handleSubmit={handleSubmit}
                    nextId="input3"
                />
                <OTPInput
                    id="input3"
                    value={inputValues.input3}
                    onValueChange={handleInputChange}
                    previousId="input2"
                    handleSubmit={handleSubmit}
                    nextId="input4"
                />
                <OTPInput
                    id="input4"
                    value={inputValues.input4}
                    onValueChange={handleInputChange}
                    previousId="input3"
                    handleSubmit={handleSubmit}
                    nextId=""
                />

            </div>
        </>
    );
}

//Our child component
const OTPInput = ({ id, previousId, nextId, value, onValueChange, handleSubmit }) => {
    //This callback function only runs when a key is released
    const handleKeyUp = (e) => {
        //check if key is backspace or arrowleft
        if (e.keyCode === 8 || e.keyCode === 37) {
            //find the previous element
            const prev: any = document.getElementById(previousId);
            if (prev) {
                //select the previous element
                prev.select();
            }
        } else {
            //find the next element
            const next: any = document.getElementById(nextId);
            if (next) {
                //select the next element
                next.select();
            } else {
                //check if inputGroup has autoSubmit enabled
                const inputGroup = document.getElementById('OTPInputGroup');
                if (inputGroup && inputGroup.dataset['autosubmit']) {
                    //submit the form
                    handleSubmit();
                }
            }
        }
    }
    return (
        <div className="w-16 h-16 ">
            <input
                id={id}
                name={id}
                type="text"
                className="w-full h-full flex flex-col items-center justify-center text-center px-5 outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                value={value}
                maxLength={1}
                onChange={(e) => onValueChange(id, e.target.value)}
                onKeyUp={handleKeyUp}
            />
        </div>
    );
};

export default OTPInputGroup;