import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import './index.css';
import './satoshi.css';
import { Provider } from 'react-redux';
import store from './redux/store';
import configureStore from './redux/store';
import { interceptorCall } from './common/Utils/interceptor';
interceptorCall();
// console.log('running')

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  // <React.StrictMode>
    <Provider store={configureStore()}>
      <Router>
        <App />
      </Router>
    </Provider>
  //  </React.StrictMode>
);
