import axios from "axios";
import { API_URL } from "../../../common/Utils/constant";

export const getAllUsers = () => {
    return async (dispatch) => {
        return axios.get(`${API_URL}/user`, {
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("skyoffsite_token")}`,
            },
        }).then((res: any) => {
            dispatch({
                type: "USERS",
                data: res.data.data
            })
            return true
        }).catch((err) => {
            if (err?.message) {
                console.log(err.response.data.message)
                // alert(err.response.data.message)
            }
            return false
        })
    }
}

export const AddUserDetails = (payload) => {
    return axios.post(`${API_URL}/user/create`, payload, {
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("skyoffsite_token")}`,
        },
    }).then((res: any) => {

        return true
    }).catch((err) => {
        if (err?.message) {
            console.log(err.response.data.message)
            // alert(err.response.data.message)
        }
        return false
    })
}

export const EditUserDetails = (payload, id) => {
    return axios.put(`${API_URL}/user/${id}`, payload, {
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("skyoffsite_token")}`,
        },
    }).then((res: any) => {

        return true
    }).catch((err) => {
        if (err?.message) {
            console.log(err.response.data.message)
            // alert(err.response.data.message)
        }
        return false
    })
}

export const deactivateUser = async (id: string) => {
    return axios.put(`${API_URL}/user/deactivate/${id}`, {}, {
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("skyoffsite_token")}`,
        },
    }).then((res) => {
        return true;
    }).catch((err) => {
        if (err?.message) {
            console.log(err.response.data.message)
            // alert(err.response.data.message)
        }
        // console.log(err)
        return false
    })
}