import rootReducer from './reducer'
import thunk from 'redux-thunk'
import { createLogger } from "redux-logger";
import promise from 'redux-promise-middleware';
// import { applyMiddleware, configureStore } from '@reduxjs/toolkit';
import { applyMiddleware, createStore } from 'redux';
// import { compose } from 'redux'

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const logger = createLogger({
  // ...options
});

export default function configureStore(initialState = {}) {
  return createStore(
    rootReducer,
    initialState,
    applyMiddleware(promise, thunk, logger)
  );
}

