import React, { useEffect, useRef, useState } from 'react'
import { donwloadDropboxFile, listDropboxFiles, uploadDropbox } from './Dependencies/action';
// import { Dropbox } from 'dropbox';
import axios from 'axios';
import Loader from '../../common/Loader';
import { Field, Form, Formik, FormikProps } from 'formik';
import { API_URL } from '../../common/Utils/constant';
// const access_token = "sl.BqBXiOn125w5VN0RwWnUE5AZg2MIP1nsXIUvzdi-3ZNrwyCv3UVg_ai1yRmqrqhWJB2ABrJO8Mk4QE1w0UOaFycF49n8l69mZZWWzApGphInSXdSGnczKfa4ojTDUASVv0Jsqf6GdppAanz5B5PRVMY";
// const access_token = "sl.BqEWWI0ZzvslTCfxODtNSIlHnttqlMmcGV4oFj4_OJh0Ot0h9P3aWWcuLq74qMoA55fj4thJf05FPqlQX86-Frx3xIMit2UyZhtpE6ZOicVD7GkcZPBvdg3avdKDuQJIpz7X14hAf4h49FAhCAe4_vc";
const access_dropbox = () => {
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://api.dropbox.com/oauth2/token?refresh_token=Yp6fPRLHS1IAAAAAAAAAARFaNUkkKQ-zhG-tXaJrPgA3_vZTwqiq1OD7uEJMrCUS&grant_type=refresh_token&client_id=lgpydiu3aij5cvd&client_secret=ro8hkd3y733ctz7',
        headers: {
            'Cookie': 'locale=en; t=T3Y1kZv-jXBSDXvWDXCem9a0'
        }
    };

    return axios.request(config)
        .then((response) => {
            console.log(JSON.stringify(response.data));
            if (response?.data) {
                return response.data.access_token
            }
        })
        .catch((error) => {
            console.log(error);
        });
}

const ReportUpload = ({ order_number, report_type }) => {
    const [loading, setLoading] = useState(false);
    const fileInputRef = useRef(null);
    const previousFileRef = useRef(null);
    const [files, setFiles] = useState([]);

    useEffect(() => {
        if (order_number && report_type) {
            listDropboxFiles(order_number, report_type).then((res) => {
                if (res?.data) {
                    setFiles(res.data);
                }
            })
        }
    }, []);

    const onDrop = async (e) => {
        setLoading(true);
        if (!e.target.files[0]) return;

        const currentFile = e.target.files[0];
        const previousFile = previousFileRef.current;
        console.log("asdasdsa", order_number, report_type)
        if (previousFile === currentFile) {
            // Same file selected
            await uploadDropbox(currentFile, order_number, report_type);

            console.log("Same file selected");
        } else {
            // New file selected
            previousFileRef.current = currentFile;
            // You can do something with the selected file here
            await uploadDropbox(currentFile, order_number, report_type);
        }

        // Clear the input field to allow reselection of the same file
        fileInputRef.current = "";

        listDropboxFiles(order_number, report_type).then((res) => {
            if (res?.data) {
                setFiles(res.data);
                setLoading(false);
            }
        })
    };

    const downloadFile = async (d) => {
        setLoading(true);
        let data = {
            path: d.id
        };
        const access_token = await access_dropbox();
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://api.dropboxapi.com/2/files/get_temporary_link',
            headers: {
                // 'Dropbox-API-Arg': `{"path" : "${d.id}"}`,
                'Authorization': `Bearer ${access_token}`,
                "Content-Type": "application/json"
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                // Buffer.from(response.data).toString('base64');

                // downloadOctetStream(response.data, d.name, 'application/pdf');
                const url = response.data.link;
                const downloadLink = document.createElement('a');
                // const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
                downloadLink.href = url;
                downloadLink.setAttribute('download', d.name);
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
                setLoading(false)
            })
            .catch((error) => {
                console.log(error);
                setLoading(false)
            });
    }

    function downloadOctetStream(data, fileName, mimeType) {
        // Create a Blob from the octet-stream data
        const blob = new Blob([data]);
        console.log(blob)
        // Create a download link
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;

        // Append the link to the body and click it to trigger the download
        document.body.appendChild(link);
        link.click();

        // Remove the link from the body
        document.body.removeChild(link);
    }


    return loading ? (
        <Loader />
    ) : (
        <>
            <div className="w-full">
                <label
                    className="flex justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
                    <span className="flex items-center space-x-2">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-600" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                        </svg>
                        <span className="font-medium text-gray-600">
                            Drop files to Attach, or
                            <span className="text-blue-600 underline">browse</span>
                        </span>
                    </span>
                    <input type="file" name="file_upload" ref={fileInputRef} className="hidden" onChange={onDrop} />
                </label>
            </div>
            <section className="text-gray-600 body-font">
                <div className="container px-5 py-6 mx-auto flex flex-wrap">

                    <div className="flex flex-wrap -m-4">
                        {files.length > 0 && files.map((d) => {
                            return <div className="p-4">
                                <div className="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col">
                                    <div className="flex-grow">
                                        <h2 className="text-gray-900 text-lg title-font font-medium mb-3">{d.name}</h2>
                                        {/* <p className="leading-relaxed text-base">To Download File Click Below Download Button.</p> */}
                                        <a className="mt-3 text-indigo-500 inline-flex items-center cursor-pointer text-strokedark" onClick={() => {
                                            downloadFile(d)
                                        }}>Download File
                                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                                <path d="M5 12h14M12 5l7 7-7 7"></path>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        })}

                    </div> 
                </div>
            </section>
            <Formik
                initialValues={{ none_data: "" }}
                onSubmit={async (values) => {
                    // const data = await setLeadWorkChange(values, id);
                    if (values.filename) {
                        // alert(data);
                        axios.post(`${API_URL}/folder/create`, {
                            filename: values.filename,
                            order_no: order_number,
                            order_size: report_type,
                        }, {
                            headers: {
                                "content-type": "application/json",
                                Authorization: `Bearer ${localStorage.getItem("skyoffsite_token")}`,
                            },
                        }).then((res: any) => {

                            return res.data.data;
                        }).catch((err) => {
                            if (err?.message) {
                                console.log(err.response.data.message)
                                // alert(err.response.data.message)
                            }
                            return false
                        })
                        window.location.reload();
                    }
                }}
            >
                {(props: FormikProps<any>) => (
                    <Form>
                        <div className='mb-4.5 flex flex-col gap-6 xl:flex-row'>
                            <div className="w-full xl:w-1/3">
                                <label className="mb-2.5 block text-black dark:text-white">
                                    Folder Name
                                </label>
                                <Field type="text" name="filename" className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary" placeholder="Enter File Name" />
                            </div>
                        </div>
                        <div className='flex justify-between'>
                            <input
                                type="submit"
                                className="my-5 inline-flex items-center justify-center rounded-lg bg-primary text-center font-medium text-white hover:bg-opacity-90 px-4 py-1"
                                value="Create Folder" />
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default ReportUpload