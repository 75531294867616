import axios from "axios";
import { API_URL } from "../../../common/Utils/constant";

export const getUserOrders = (id) => {
    return axios.get(`${API_URL}/lead/orders/${id}`, {
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("skyoffsite_token")}`,
        }
    }).then((res: any) => {
        return res.data;
    }).catch((err) => {
        if (err?.message) {
            console.log(err.response.data.message)
            // alert(err.response.data.message)
        }
        return false
    })
}