import React, { useState } from 'react';
import {
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    InputAdornment,
    TextField,
    TablePagination,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Box,
} from '@mui/material';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { doneAllLeadsQuery, getLeadData } from '../../pages/MyLead/Dependencies/action';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';

const rowsPerPageOptions = [5, 10, 25];

const LeadTable = ({ data, setStartdate, setEnddate, startdate, enddate }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [search, setSearch] = useState('');

    const [label, setLabel] = useState(0);

    const { authUserData } = useSelector((state: any) => state.authReducer);
    const { users } = useSelector((state: any) => state.userReducer);

    const navigate = useNavigate();
    const getLeadDetails = (data: any, item: any) => {
        if (authUserData.user_type === "ADMIN" || authUserData.user_type === "MANAGER") {
            return navigate(`/dashboard/orders/${data}`);
        } else if (authUserData.user_type === "TEAM_LEADER" || authUserData.user_id == item.lead_verified_by) {
            return navigate(`/dashboard/orders/team_leader/${data}`);
        } else {
            return navigate(`/dashboard/orders/employee/${data}`);
        }
    }

    const dispatch: any = useDispatch();
    const rangeDate = async () => {
        const payload = {
            startdate: startdate,
            enddate: enddate
        }
        dispatch(getLeadData(payload));
    }

    const doneAllLeads = async () => {
        if (window.confirm('ek baar fir soch lo, revert ni ho paega !!!!')) {
            // Save it!
            console.log('Thing was saved to the database.');

            doneAllLeadsQuery();
        } else {
            // Do nothing!
            console.log('Thing was not saved to the database.');
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredData = data.filter(item => {
        switch (label) {
            case 1:
                return moment(item.createdAt).format('MMMM Do, h:mm:ss a')?.toLowerCase().includes(search.toLowerCase());
            case 2:
                return item.lead_order_number?.toLowerCase().includes(search.toLowerCase());
            case 3:
                return item.lead_report_type?.toLowerCase().includes(search.toLowerCase());
            case 4:
                return item?.lead_report_detail && item.lead_report_detail.report_qty?.toLowerCase().includes(search.toLowerCase());
            case 5:
                return item?.lead_report_detail && item.lead_report_detail.report_address?.toLowerCase().includes(search.toLowerCase());
            case 6:
                return item?.lead_report_detail && item.lead_report_detail.report_customer_pitch?.toLowerCase().includes(search.toLowerCase());
            case 7:
                return item?.lead_report_detail && item.lead_report_detail.report_delivery?.toLowerCase().includes(search.toLowerCase());
            case 8:
                return item?.lead_report_detail && item.lead_report_detail.report_customer_notes?.toLowerCase().includes(search.toLowerCase());
            case 9:
                return item?.lead_report_detail && item.lead_report_detail.report_employee_pitch?.toLowerCase().includes(search.toLowerCase());
            case 10:
                return item.lead_status?.toLowerCase().includes(search.toLowerCase());
            case 11:
                return item?.lead_report_detail && item.lead_report_detail.report_generated_report_size?.toLowerCase().includes(search.toLowerCase());

            default:
                return true;

        }
    });

    const handleLabelChange = (e) => {
        setLabel(e.target.value);
    }
    return (
        <div>
            <div className='flex justify-between'>
                <div className='grid grid-cols-12 gap-2'>
                    <FormControl className='col-span-2 dark:bg-white'>
                        <InputLabel id="demo-simple-select-label">Label</InputLabel>

                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={label}
                            label="Label"
                            onChange={handleLabelChange}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={1}>Date</MenuItem>
                            <MenuItem value={2}>Order No</MenuItem>
                            <MenuItem value={3}>Size</MenuItem>
                            <MenuItem value={4}>Qty</MenuItem>
                            <MenuItem value={5}>Address</MenuItem>
                            <MenuItem value={6}>Format/Pitch</MenuItem>
                            <MenuItem value={7}>Delivery</MenuItem>
                            <MenuItem value={8}>Notes</MenuItem>
                            <MenuItem value={9}>Predominant Pitch</MenuItem>
                            <MenuItem value={10}>Status</MenuItem>
                            <MenuItem value={11}>Generated Report Size</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        label="Filter by Order"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    🔍
                                </InputAdornment>
                            ),
                        }}
                        className='col-span-2 dark:bg-white'
                    />
                    {authUserData.user_type === "ADMIN" || authUserData.user_type === "MANAGER" || authUserData.user_type === "TEAM_LEADER" ?
                        <div className='col-span-6 mb-3'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker
                                        format="YYYY-MM-DD" label="Start date picker" value={dayjs(startdate)} onChange={(newValue) => {
                                            const date: any = new Date(newValue?.$d);
                                            // console.log(date === 'Invalid Date');
                                            if (date instanceof Date && !isNaN(date)) {
                                                // console.log('run', date);
                                                setStartdate(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`)
                                            }
                                        }} />

                                    <DatePicker label="End date picker" format="YYYY-MM-DD" value={dayjs(enddate)} onChange={(newValue) => {
                                        const date: any = new Date(newValue?.$d);
                                        if (date instanceof Date && !isNaN(date)) {
                                            // console.log('run', date);
                                            setEnddate(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`)
                                        }
                                    }} />
                                    <input
                                        value="Search Lead"
                                        onClick={rangeDate}
                                        className="mb-3 inline-flex items-center cursor-pointer justify-center rounded-lg bg-primary text-center font-medium text-white hover:bg-opacity-90 px-4 py-1"
                                    />
                                </DemoContainer>
                            </LocalizationProvider>

                        </div>
                        : ""}
                    {authUserData.user_type === "ADMIN" || authUserData.user_type === "MANAGER" || authUserData.user_type === "TEAM_LEADER" ? <input
                        value="Done All Leads"
                        onClick={doneAllLeads}
                        className="mb-3 col-span-1 inline-flex items-center cursor-pointer justify-center rounded-lg bg-primary text-center font-medium text-white hover:bg-opacity-90 px-4 py-1"
                    /> : ""}
                </div>
                {authUserData.user_type === "ADMIN" || authUserData.user_type === "MANAGER" ? <Link
                    to="/dashboard/orders/add"
                    className="mb-3 col-span-1 inline-flex items-center justify-center rounded-lg bg-primary text-center font-medium text-white hover:bg-opacity-90 px-4 py-1"
                >
                    Add Lead
                </Link> : ""}
            </div>
            <TableContainer component={Paper}>
                <Table className='w-full table-auto'>
                    <TableHead>
                        <TableRow className='bg-gray-2 text-left dark:bg-meta-4'>
                            <TableCell className="min-w-[120px] py-4 px-4 font-bold text-black dark:text-white">
                                Date
                            </TableCell>
                            <TableCell className="min-w-[180px] py-4 px-4 font-bold text-black dark:text-white xl:pl-11">
                                Order No
                            </TableCell>
                            <TableCell className="py-4 px-4 font-bold text-black dark:text-white xl:pl-11">
                                Size
                            </TableCell>
                            <TableCell className="py-4 px-4 font-bold text-black dark:text-white">
                                Qty
                            </TableCell>
                            <TableCell className="min-w-[220px] py-4 px-4 font-bold text-black dark:text-white">
                                Address
                            </TableCell>
                            <TableCell className="py-4 px-4 font-bold text-black dark:text-white">
                                Format/Pitch
                            </TableCell>
                            <TableCell className="py-4 px-4 font-bold text-black dark:text-white">
                                Delivery
                            </TableCell>
                            <TableCell className="py-4 px-4 font-bold text-black dark:text-white">
                                Notes
                            </TableCell>
                            <TableCell className="py-4 px-4 font-bold text-black dark:text-white">
                                Predominant Pitch
                            </TableCell>
                            <TableCell className="py-4 px-4 font-bold text-black dark:text-white">
                                Status
                            </TableCell>
                            <TableCell className="py-4 px-4 font-bold text-black dark:text-white">
                                Start Time
                            </TableCell>
                            <TableCell className="py-4 px-4 font-bold text-black dark:text-white">
                                Finish Time
                            </TableCell>
                            <TableCell className="py-4 px-4 font-bold text-black dark:text-white">
                                Taken By
                            </TableCell>
                            <TableCell className="py-4 px-4 font-bold text-black dark:text-white">
                                Generated By
                            </TableCell>
                            <TableCell className="py-4 px-4 font-bold text-black dark:text-white">
                                Verified By
                            </TableCell>
                            <TableCell className="py-4 px-4 font-bold text-black dark:text-white">
                                Generated Report Size
                            </TableCell>
                            <TableCell className="py-4 px-4 font-bold text-black dark:text-white">
                                Send / NCM
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((item) => (
                                <TableRow key={item.id} className='dark:bg-meta-4'>
                                    <TableCell className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                                        <h5 className="font-medium text-black dark:text-white">
                                            {/* {moment(item.createdAt).format('MMMM Do, h:mm:ss a')} */}
                                            {item.createdAt.split('T')[0]}
                                        </h5>
                                    </TableCell>
                                    <TableCell className="border-b border-[#eee] cursor-pointer py-5 px-4 pl-9 dark:border-strokedark xl:pl-11" onClick={() => getLeadDetails(item.lead_id, item)}>
                                        <h5 className="font-medium text-black hover:text-[#e1ad01] dark:text-white">
                                            {item.lead_order_number}
                                        </h5>
                                    </TableCell>
                                    <TableCell className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                        <p className="text-black dark:text-white">{item.lead_report_type}</p>
                                    </TableCell>

                                    <TableCell className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                        <p className="text-black dark:text-white">{item?.lead_report_detail && item.lead_report_detail.report_qty}</p>
                                    </TableCell>
                                    <TableCell className={`border-b border-[#eee] py-5 px-4 dark:border-strokedark ${item.lead_report_detail?.report_address === "Rapid" ? 'bg-[#008000] text-white' : ''}`}>
                                        <p className="text-black dark:text-white">{item?.lead_report_detail && item.lead_report_detail.report_address}</p>
                                    </TableCell>
                                    <TableCell className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                        <p className="text-black dark:text-white">{item?.lead_report_detail && item.lead_report_detail.report_customer_pitch}</p>
                                    </TableCell>
                                    <TableCell className={`border-b border-[#eee] py-5 px-4 dark:border-strokedark ${item.lead_report_detail?.report_delivery === "rush" ? 'bg-meta-1 text-white' : ''}`}>
                                        <p className="text-black dark:text-white">{item?.lead_report_detail && item.lead_report_detail.report_delivery}</p>
                                    </TableCell>

                                    <TableCell className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                        <p className="text-black dark:text-white">{item?.lead_report_detail && item.lead_report_detail.report_customer_notes}</p>
                                    </TableCell>
                                    <TableCell className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                        <p className="text-black dark:text-white">{item?.lead_report_detail && item.lead_report_detail.report_employee_pitch}</p>
                                    </TableCell>
                                    <TableCell className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                        <p className="inline-flex rounded-full bg-success bg-opacity-10 py-1 px-3 text-sm font-medium text-success">
                                            {item.lead_status}
                                        </p>
                                    </TableCell>
                                    <TableCell className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                        <p className="text-black dark:text-white">{item.lead_start_time && moment(item.lead_start_time).format('h:mm a')}</p>
                                    </TableCell>
                                    <TableCell className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                        <p className="text-black dark:text-white">{item.lead_finish_time && moment(item.lead_finish_time).format('h:mm a')}</p>
                                    </TableCell>
                                    <TableCell className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                        <p className="text-black dark:text-white">{users.length > 0 && users.filter((data: any) => {
                                            return data.user_id == item.lead_taken_by
                                        })[0]?.user_name}</p>
                                    </TableCell>
                                    <TableCell className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                        <p className="text-black dark:text-white">{users.length > 0 && users.filter((data: any) => {
                                            return data.user_id == item.lead_generated_by
                                        })[0]?.user_name}</p>
                                    </TableCell>
                                    <TableCell className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                        <p className="text-black dark:text-white">{users.length > 0 && users.filter((data: any) => {
                                            return data.user_id == item.lead_verified_by
                                        })[0]?.user_name}</p>
                                    </TableCell>
                                    <TableCell className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                        <p className="text-black dark:text-white">{item?.lead_report_detail && item.lead_report_detail.report_generated_report_size}</p>
                                    </TableCell>
                                    <TableCell className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                        <p className="text-black dark:text-white">{item.lead_report_detail?.report_send}</p>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
};

export default LeadTable;
