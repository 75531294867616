import axios from "axios";


export const interceptorCall = () => {
    axios.interceptors.request.use(
        (req) => {
            // Add configurations here
            console.log(req)
            return req;
        },
        (err) => {
            return Promise.reject(err);
        }
    );

    // console.log('inter')
    axios.interceptors.response.use(
        (res) => {
            // console.log("asdasd", res)
            // Add configurations here
            // if (res.status === 401) {
            //     alert('unathorized user');
            // } 
            return res;
        }, (err) => {
            if (err.response.status === 403) {
                const domain_url = (new URL(window.location.href));
                localStorage.clear();
                window.location.replace(domain_url.origin
                    + "/");
            } else if (err.response.status === 401) {
                const domain_url = (new URL(window.location.href));
                localStorage.clear();
                window.location.replace(domain_url.origin
                    + "/");
            }
            return Promise.reject(err);
        })
}