import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import UserTable from '../../components/UserTable';
import { getAllUsers } from './Dependencies/action';

const Users = () => {
  const dispatch: any = useDispatch();
  useEffect(() => {
    dispatch(getAllUsers());
  }, [])
  return (
    <>
      <UserTable />
    </>
  )
}

export default Users