import axios from "axios";
import { API_URL, localStorage } from "../../../common/Utils/constant";

const AuthLoginUser = (data) => {
    const payload = {
        user_email: data.userName,
        user_password: data.password
    }
    return async (dispatch) => {
        return axios.post(`${API_URL}/auth/login`, payload).then((res: any) => {
            // console.log(res);
            // console.log(res.data.accessToken);
            // localStorage({
            //     key: "skyoffsite_token",
            //     value: res.data.accessToken,
            // });
            // dispatch({
            //     type: "AUTH_DATA",
            //     data: res.data.user_details
            // })
            return res.data
        }).catch((err) => {
            if (err?.message) {
                // alert(err.message)
                console.log(err.response.data.message)
                alert(err.response.data.message)
            }
            return false
        })

    }
}

export const AuthVerifyOtp = (data) => {
    const payload = {
        session_id: data.session_id,
        otp: data.otp
    }
    return async (dispatch) => {
        return axios.post(`${API_URL}/auth/verify/otp`, payload).then((res: any) => {
            // console.log(res);
            // console.log(res.data.accessToken);
            localStorage({
                key: "skyoffsite_token",
                value: res.data.accessToken,
            });
            dispatch({
                type: "AUTH_DATA",
                data: res.data.user_details
            })
            return true
        }).catch((err) => {
            if (err?.message) {
                // alert(err.message)
                console.log(err.response.data.message)
                alert(err.response.data.message)
            }
            return false
        })

    }
}

export const getLoginUserDetails = () => {
    return async (dispatch) => {
        return axios.get(`${API_URL}/login/user`, {
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${window.localStorage.getItem("skyoffsite_token")}`,
            },
        }).then((res: any) => {
            dispatch({
                type: "AUTH_DATA",
                data: res.data.user_details
            })
            return true
        }).catch((err) => {
            if (err?.message) {
                // alert(err.message)
                console.log(err.response.data.message)
                // alert(err.response.data.message)
            }
            return false
        })
    }
}

export default AuthLoginUser;