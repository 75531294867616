import { combineReducers } from "redux";
import { authReducer } from "../pages/Authentication/Dependencies/reducer";
import { leadData } from "../pages/MyLead/Dependencies/reducer";
import { userReducer } from "../pages/Users/Dependencies/reducer";
import { leadReportReducer } from "../pages/Dashboard/Dependencies/reducer";

const rootReducer = combineReducers({
    authReducer,
    leadData,
    userReducer,
    leadReportReducer
});

export default rootReducer;