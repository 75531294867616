import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, redirect, useLocation, useNavigate } from "react-router-dom";
import { getAllUsers } from "../pages/Users/Dependencies/action";
import { API_URL } from "../common/Utils/constant";
import axios from "axios";
import { FormControl, InputAdornment, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import moment from "moment";
// import ReactPaginate from "react-paginate";

const rowsPerPageOptions = [5, 10, 25];

const LogsTable = ({ logs }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [search, setSearch] = useState('');

    const [label, setLabel] = useState(0);

    const { authUserData } = useSelector((state: any) => state.authReducer);
    const { users } = useSelector((state: any) => state.userReducer);

    const navigate = useNavigate();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredData = logs.filter(item => {
        switch (label) {
            case 1:
                return moment(item.createdAt).format('MMMM Do, h:mm:ss a')?.toLowerCase().includes(search.toLowerCase());
            case 2:
                return item.logs_lead_id?.toLowerCase().includes(search.toLowerCase());
            case 3:
                return users.length > 0 && users.filter((data: any) => {
                    return data.user_id == item.logs_created_by
                })[0]?.user_name?.toLowerCase().includes(search.toLowerCase());
            case 4:
                return item.logs_order_id?.toLowerCase().includes(search.toLowerCase());

            default:
                return true;

        }
    });

    const handleLabelChange = (e) => {
        setLabel(e.target.value);
    }

    return (
        <div>
            <div className='flex justify-between'>
                <div className='grid grid-cols-6 gap-2'>
                    <FormControl className='col-span-2 dark:bg-white'>
                        <InputLabel id="demo-simple-select-label">Label</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={label}
                            label="Label"
                            onChange={handleLabelChange}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={1}>Date</MenuItem>
                            <MenuItem value={2}>Lead Id</MenuItem>
                            <MenuItem value={3}>Created By</MenuItem>
                            <MenuItem value={4}>Order No</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        label="Filter by Order"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    🔍
                                </InputAdornment>
                            ),
                        }}
                        className='col-span-3 dark:bg-white'
                    />
                </div>
            </div>
            <TableContainer component={Paper}>
                <Table className='w-full table-auto'>
                    <TableHead>
                        <TableRow className='bg-gray-2 text-left dark:bg-meta-4'>
                            <TableCell className="min-w-[120px] py-4 px-4 font-bold text-black dark:text-white">
                                Date
                            </TableCell>
                            <TableCell className="min-w-[180px] py-4 px-4 font-bold text-black dark:text-white xl:pl-11">
                                Order No
                            </TableCell>
                            <TableCell className="min-w-[180px] py-4 px-4 font-bold text-black dark:text-white xl:pl-11">
                                User Name
                            </TableCell>
                            <TableCell className="min-w-[180px] py-4 px-4 font-bold text-black dark:text-white xl:pl-11">
                                Operation
                            </TableCell>
                            <TableCell className="min-w-[180px] py-4 px-4 font-bold text-black dark:text-white xl:pl-11">
                                Previous Data
                            </TableCell>
                            <TableCell className="min-w-[180px] py-4 px-4 font-bold text-black dark:text-white xl:pl-11">
                                Current Data
                            </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((item) => (
                                <TableRow key={item.id} className='dark:bg-meta-4'>
                                    <TableCell className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                                        <h5 className="font-medium text-black dark:text-white">
                                            {moment(item.createdAt).format('MMMM Do, h:mm:ss a')}
                                        </h5>
                                    </TableCell>
                                    <TableCell className="border-b border-[#eee] cursor-pointer py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                                        <h5 className="font-medium text-black hover:text-[#e1ad01] dark:text-white">
                                            {item.logs_order_id}
                                        </h5>
                                    </TableCell>
                                    <TableCell className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                        <p className="text-black dark:text-white">{item.logs_created_by}</p>
                                    </TableCell>
                                    <TableCell className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                        <p className="text-black dark:text-white">{item.logs_operation}</p>
                                    </TableCell>

                                    <TableCell className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                                        <p className="text-black dark:text-white">{item?.logs_previous_data && item.logs_previous_data}</p>
                                    </TableCell>
                                    <TableCell className={`border-b border-[#eee] py-5 px-4 dark:border-strokedark ${item.lead_report_detail?.report_address === "Rapid" ? 'bg-[#008000] text-white' : ''}`}>
                                        <p className="text-black dark:text-white">{item?.logs_change_data && item.logs_change_data}</p>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
};

export default LogsTable;
