import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { MenuItem, TextField } from '@mui/material';
import moment from 'moment';
import { adminMarkAttendence, deleteAttendence } from '../../pages/Attendence/ManageMarkAttendence/Dependencies/action';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const over_all = [
    {
        value: 'P',
        label: 'Present',
    },
    {
        value: 'L',
        label: 'Leave',
    },
    {
        value: 'WO',
        label: 'Week-Off',
    },
    {
        value: 'PL',
        label: 'Penalty Leave',
    },
    {
        value: 'HD',
        label: 'Half-Day',
    },
];

export default function TransitionsModal({ openOrder, setOpenOrder, checked, selectMonth, day, user_id, attendence_id }) {
    // const [open, setOpen] = React.useState(openOrder);
    const handleClose = () => setOpenOrder(false);
    const [reason, setReason] = React.useState("");
    const [attendence, setAttendence] = React.useState("");

    const addAttendence = async () => {
        if (attendence) {
            if (checked) {
                const payload = {
                    check_in: moment(`${moment().format("YYYY")}-${selectMonth}-${day}`).format('hh:mm:ss'),
                    check_out: moment(`${moment().format("YYYY")}-${selectMonth}-${day}`).format('hh:mm:ss'),
                    user_id,
                    login_date: moment(`${moment().format("YYYY")}-${selectMonth}-${day}`).format('YYYY-MM-DD'),
                    message: attendence,
                    reason: reason
                }
                const data = await adminMarkAttendence(payload);
                if (data) {
                    window.location.reload();
                }
            } else {
                const data = await deleteAttendence(attendence_id);
                if (data) {
                    window.location.reload();
                }
            }
        } else {
            alert("Please Select Attendence Type");
        }
        handleClose()
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openOrder}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={openOrder}>
                    <Box
                        sx={style}
                    >
                        <div className='text-center'>
                            <div className='my-5'>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    label="Select"
                                    defaultValue="PL"
                                    helperText="Please select type"
                                    className='w-full'
                                    value={attendence}
                                    onChange={(d: any) => {
                                        setAttendence(d.target.value);
                                    }}
                                >
                                    {over_all.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                            <div>
                                <TextField
                                    className='w-full'
                                    id="outlined-multiline-static"
                                    label="Reason (*Optional)"
                                    multiline
                                    rows={4}
                                    placeholder='Any Reason'
                                    onChange={(d) => {
                                        setReason(d.target.value)
                                    }}
                                    value={reason}
                                />
                            </div>
                            <div className='mt-5'>
                                <Button variant="outlined" onClick={addAttendence}>Add</Button>
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}