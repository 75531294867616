export function leadReportReducer(state = {
    lead_employee_report: [],
}, action) {
    switch (action.type) {
        case 'EMPLOYEE_REPORT': {
            return {
                ...state,
                lead_employee_report: action.data,
            };
        }

        default: {
            return state;
        }
    }
}