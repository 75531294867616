import axios from "axios"
import { API_URL } from "../../../common/Utils/constant"

export const setLeadEmployeeReport = () => {
    return async (dispatch) => {
        return axios.get(`${API_URL}/lead/employee/count`, {
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("skyoffsite_token")}`,
            },
        }).then((res: any) => {
            const data = res.data.data.filter(user_report => !(user_report.made_by === 0 && user_report.made_by === 0 && user_report.made_by === 0))
            dispatch({
                type: "EMPLOYEE_REPORT",
                data: data
            })
            return true
        }).catch((err) => {
            if (err?.message) {
                console.log(err.response.data.message)
                // alert(err.response.data.message)
            }
            return false
        })
    }
}