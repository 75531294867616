import Motivate from '../../images/motivate/motivate1.jpeg';
import Motivate2 from '../../images/motivate/motivate2.jpeg';
import m3 from '../../images/motivate/m10.jpeg';
import m4 from '../../images/motivate/m11.jpeg';
import m5 from '../../images/motivate/m12.jpeg';
import m6 from '../../images/motivate/m13.jpeg';
import m7 from '../../images/motivate/m14.jpeg';
import m8 from '../../images/motivate/m15.jpg';
import m9 from '../../images/motivate/m16.jpg';
import m10 from '../../images/motivate/m17.jpg';
import m11 from '../../images/motivate/m18.jpg';
import m12 from '../../images/motivate/m19.jpg';
import m13 from '../../images/motivate/m9.jpeg';
import m14 from '../../images/motivate/m8.jpeg';
import m15 from '../../images/motivate/m20.jpg';
import m16 from '../../images/motivate/m22.jpg';
import m17 from '../../images/motivate/m8.jpeg';
import MarkAttendence from '../Attendence/MarkAttendence/MarkAttendence';
import EmployeeReport from '../../components/EmployeeReport';
import AbsentEmployees from '../../components/AbsentEmployess';

const ECommerce = () => {
  return (
    <>
      <div className="grid grid-cols-12 gap-4 mb-4">
        <div className='col-span-12 md:col-span-4 w-full'>
          <MarkAttendence />
        </div>
        <div className='col-span-12 md:col-span-5 w-full'>
          <EmployeeReport />
        </div>

        <div className='col-span-12 md:col-span-3 w-full'>
          <AbsentEmployees />
        </div>
      </div>

      <h1 className='font-bold text-center text-4xl my-10 underline'>Motivation Quotes</h1>
      <div className="grid grid-cols-4 gap-4">
        <img
          src={Motivate}
          alt="profile cover"
          className="h-full w-full rounded-tl-sm rounded-tr-sm object-cover object-center"
        />
        <img
          src={Motivate2}
          alt="profile cover"
          className="h-full w-full rounded-tl-sm rounded-tr-sm object-cover object-center"
        />
        <img
          src={m3}
          alt="profile cover"
          className="h-full w-full rounded-tl-sm rounded-tr-sm object-cover object-center"
        />
        <img
          src={m4}
          alt="profile cover"
          className="h-full w-full rounded-tl-sm rounded-tr-sm object-cover object-center"
        />
        <img
          src={m5}
          alt="profile cover"
          className="h-full w-full rounded-tl-sm rounded-tr-sm object-cover object-center"
        />
        <img
          src={m6}
          alt="profile cover"
          className="h-full w-full rounded-tl-sm rounded-tr-sm object-cover object-center"
        />
        <img
          src={m7}
          alt="profile cover"
          className="h-full w-full rounded-tl-sm rounded-tr-sm object-cover object-center"
        />
        <img
          src={m8}
          alt="profile cover"
          className="h-full w-full rounded-tl-sm rounded-tr-sm object-cover object-center"
        />
        <img
          src={m9}
          alt="profile cover"
          className="h-full w-full rounded-tl-sm rounded-tr-sm object-cover object-center"
        />
        <img
          src={m10}
          alt="profile cover"
          className="h-full w-full rounded-tl-sm rounded-tr-sm object-cover object-center"
        />
        <img
          src={m11}
          alt="profile cover"
          className="h-full w-full rounded-tl-sm rounded-tr-sm object-cover object-center"
        />
        <img
          src={m12}
          alt="profile cover"
          className="h-full w-full rounded-tl-sm rounded-tr-sm object-cover object-center"
        />
        <img
          src={m13}
          alt="profile cover"
          className="h-full w-full rounded-tl-sm rounded-tr-sm object-cover object-center"
        />
        <img
          src={m14}
          alt="profile cover"
          className="h-full w-full rounded-tl-sm rounded-tr-sm object-cover object-center"
        />
        <img
          src={m15}
          alt="profile cover"
          className="h-full w-full rounded-tl-sm rounded-tr-sm object-cover object-center"
        />
        <img
          src={m16}
          alt="profile cover"
          className="h-full w-full rounded-tl-sm rounded-tr-sm object-cover object-center"
        />
        <img
          src={m17}
          alt="profile cover"
          className="h-full w-full rounded-tl-sm rounded-tr-sm object-cover object-center"
        />
      </div>
    </>
  );
};

export default ECommerce;
