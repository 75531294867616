export function leadData(state = {
    leads: [],
    lead_data: {},
    product_list: []
}, action) {
    switch (action.type) {
        case 'ADD_LEAD': {
            return {
                ...state,
                leads: action.data,
            };
        }
        case 'LEAD_DATA': {
            return {
                ...state,
                lead_data: action.data,
            };
        }
        case 'PRODUCT_LIST': {
            return {
                ...state,
                product_list: action.data,
            };
        }

        default: {
            return state;
        }
    }
}