import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
// import LeadTable from '../../components/LeadTable';
import { getLeadData } from './Dependencies/action';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../common/Utils/constant';
import LeadTable from '../../components/NewLeadTable/LeadTable';

const MyLead = () => {
    const [count, setCount] = useState(0);
    const dispatch: any = useDispatch();

    // useEffect(() => {
    //     axios.get(`${API_URL}/leadCount`, {
    //         headers: {
    //             "content-type": "application/json",
    //             Authorization: `Bearer ${localStorage.getItem("skyoffsite_token")}`,
    //         },
    //     }).then((res: any) => {
    //         setCount(res.data.data);

    //         return true
    //     }).catch((err) => {
    //         if (err?.message) {
    //             console.log(err.response.data.message)
    //             // alert(err.response.data.message)
    //         }
    //         return false
    //     })
    // }, [])

    const { leads } = useSelector((state: any) => state.leadData);
    const date = new Date();
    const [startdate, setStartdate] = useState(new Date(date.getFullYear(), date.getMonth(), 1).toLocaleString("en-ZA", {timeZone: 'Asia/Kolkata'}).split(",")[0]);
    const [enddate, setEnddate] = useState(new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleString("en-ZA", {timeZone: 'Asia/Kolkata'}).split(",")[0]);

    useEffect(() => {
        const payload = {
            startdate: startdate,
            enddate: enddate
        }
        dispatch(getLeadData(payload));
        setInterval(() => {
            dispatch(getLeadData(payload));
        }, 10000);
    }, [])

    // const getLeadDataFilter = ({ page, pageSize }) => {
    //     dispatch(getLeadData(page, pageSize));
    // }

    // const data = [
    //     { id: 1, name: 'John', age: 30 },
    //     { id: 2, name: 'Alice', age: 25 }
    //     // Add more data here
    // ];

    return (
        <>
            {/* <LeadTable leads={leads} count={count} getLeadDataFilter={getLeadDataFilter} /> */}
            <LeadTable data={leads} setStartdate={setStartdate} setEnddate={setEnddate} startdate={startdate} enddate={enddate}/>
        </>
    )
}

export default MyLead