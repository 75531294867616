import React, { useEffect, useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import Select from 'react-select';

const RobinHoodOrderAssign = ({ defaultAssign, multiOptions, multiOptionsProduct, label, takeSelectedData, AssignData }) => {
    const [userSelected, setUserSelected] = useState([]);
    const [productSelected, setProductSelected] = useState([]);

    const [formatSelected, setFormatSelected] = useState([]);
    const [operation, setOperation] = useState({ value: 'ASSIGN', label: 'ASSIGN' });
    const [lastUser, setLastUser] = useState({});
    const [start, setStart] = useState(false);
    const selectedOption: any = [
        { value: 'VERIFIED', label: 'VERIFIED' },
        { value: 'GENERATED', label: 'GENERATED' },
        { value: 'ASSIGN', label: 'ASSIGN' },
    ]

    const selectedFormat: any = [
        { value: '', label: 'None' },
        { value: "ESX- Sketch Only", label: "ESX- Sketch Only" },
        { value: "XML- Only", label: "XML- Only" },
        { value: "PDF- Only", label: "PDF- Only" },
        { value: "ESX- Sketch with .ESX PDF File", label: "ESX- Sketch with .ESX PDF File" },
        { value: "PDF- with Material List", label: "PDF- with Material List" },
        { value: "XML- with PDF", label: "XML- with PDF" },
        { value: "ESX", label: "ESX" },
        { value: "XML", label: "XML" }
    ]

    useEffect(() => {
        if (AssignData?.assign_id) {
            setProductSelected(JSON.parse(AssignData.assign_products).map(d => { return { value: d, label: d } }));
            setUserSelected(JSON.parse(AssignData.assign_users).map(d => { return { value: d, label: multiOptions.filter(d2 => d === d2.value)[0]?.label } }));
            setOperation({ value: AssignData.assign_crojob, label: AssignData.assign_crojob });
            setFormatSelected(JSON.parse(AssignData.assign_format).map(d => { return { value: d, label: d } }));
            setLastUser({ value: AssignData.user_assign_order, label: multiOptions.filter(d2 => AssignData.user_assign_order === d2.value)[0]?.label })
            AssignData.assign_id ? setStart(true) : setStart(false);
        }
    }, []);
    console.log(operation)
    return (
        <div>
            <h1 className='text-lg my-3'>Auto Assign Orders ({label})</h1>
            <div className="grid grid-cols-12 gap-3">
                <MultiSelect
                    options={multiOptions}
                    value={userSelected}
                    onChange={setUserSelected}
                    labelledBy="Select"
                    className="col-span-12 md:col-span-2"
                />
                <MultiSelect
                    options={multiOptionsProduct}
                    value={productSelected}
                    onChange={setProductSelected}
                    labelledBy="Select"
                    className="col-span-12 md:col-span-2"
                />
                <MultiSelect
                    options={selectedFormat}
                    value={formatSelected}
                    onChange={setFormatSelected}
                    labelledBy="Select"
                    className="col-span-12 md:col-span-2"
                />
                <Select
                    value={lastUser}
                    onChange={(e: any) => {
                        console.log(lastUser)
                        setLastUser(e);
                    }}
                    options={multiOptions}
                    className="col-span-12 md:col-span-2"
                />
                {/* <Select
                    value={operation}
                    onChange={(e: any) => {
                        console.log(operation)
                        setOperation(e);
                    }}
                    options={selectedOption}
                    className="col-span-2"
                /> */}

                {!start ? <button onClick={() => {
                    console.log(userSelected)
                    if (userSelected.length <= 0 && productSelected.length <= 0) {
                        alert('Please Select User & Product');
                        return
                    }
                    setStart(true);
                    takeSelectedData(userSelected, productSelected, operation, 0, formatSelected, lastUser, defaultAssign);
                }} className="flex w-full col-span-12 md:col-span-2 justify-center rounded bg-primary p-2 font-medium text-gray" type="submit">
                    Start
                </button> : <>{!defaultAssign && <button onClick={() => {
                    setStart(false);
                    setUserSelected([]);
                    setProductSelected([]);
                    takeSelectedData(userSelected, productSelected, false, AssignData.assign_id, formatSelected, null);
                }} className="flex w-full col-span-12 md:col-span-2 justify-center rounded bg-danger p-2 font-medium text-gray" type="submit">
                    Stop
                </button>}
                    <button onClick={() => {
                        takeSelectedData(userSelected, productSelected, true, AssignData.assign_id, formatSelected, lastUser);
                    }} className="flex w-full col-span-12 md:col-span-2 justify-center rounded bg-success p-2 font-medium text-gray" type="submit">
                        Edit
                    </button></>}
            </div>
        </div>
    )
}

export default RobinHoodOrderAssign