export function userReducer(state = {
    users: {},
    assign_list: []
}, action) {
    switch (action.type) {
        case 'USERS': {
            return {
                ...state,
                users: action.data,
            };
        }
        case 'ASSIGN_ORDERS': {
            return {
                ...state,
                assign_list: action.data,
            };
        }

        default: {
            return state;
        }
    }
}