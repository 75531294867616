import React, { useRef, useState } from 'react'
import { convertXMLUpload } from './Dependencies/action';

const XMLConvertor = () => {

    const [loading, setLoading] = useState(false);
    const fileInputRef = useRef(null);
    const previousFileRef = useRef(null);

    const onDrop = async (e) => {
        setLoading(true);
        if (!e.target.files[0]) return;

        const currentFile = e.target.files[0];
        const previousFile = previousFileRef.current;
        if (previousFile === currentFile) {
            // Same file selected
            // await uploadDropbox(currentFile, order_number, report_type);
            await convertXMLUpload(currentFile);
            console.log("Same file selected");
        } else {
            // New file selected
            previousFileRef.current = currentFile;
            // You can do something with the selected file here
            await convertXMLUpload(currentFile);
            // await uploadDropbox(currentFile, order_number, report_type);
        }

        // Clear the input field to allow reselection of the same file
        fileInputRef.current = "";

        // listDropboxFiles(order_number, report_type).then((res) => {
        //     if (res?.data) {
        //         setFiles(res.data);
        //         setLoading(false);
        //     }
        // })
    };

    return (
        <div className="rounded-sm border border-stroke bg-white px-5 min-h-screen pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1 overflow-x-auto">
            <div className="w-full">
                <label
                    className="flex justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
                    <span className="flex items-center space-x-2">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-600" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                        </svg>
                        <span className="font-medium text-gray-600">
                            Drop files to Attach, or
                            <span className="text-blue-600 underline">browse</span>
                        </span>
                    </span>
                    <input type="file" name="file_upload" ref={fileInputRef} className="hidden" onChange={onDrop} />
                </label>
            </div>
        </div>
    )
}

export default XMLConvertor