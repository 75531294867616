import axios from "axios";
import { API_URL } from "../../../common/Utils/constant";

export const getLeadData = (payload) => {

    return async (dispatch) => {
        return axios.post(`${API_URL}/lead/`, payload, {
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("skyoffsite_token")}`,
            },
        }).then((res: any) => {
            dispatch({
                type: "ADD_LEAD",
                data: res.data.data
            })
            return true
        }).catch((err) => {
            if (err?.message) {
                console.log(err.response.data.message)
                // alert(err.response.data.message)
            }
            return false
        })
    }
}

export const addNewLead = (payload) => {
    return axios.post(`${API_URL}/lead/create`, payload, {
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("skyoffsite_token")}`,
        },
    }).then((res: any) => {
        return true
    }).catch((err) => {
        if (err?.message) {
            console.log(err.response.data.message)
            // alert(err.response.data.message)
        }
        return false
    })
}

export const editLead = (payload, id) => {
    return axios.put(`${API_URL}/lead/edit/${id}`, payload, {
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("skyoffsite_token")}`,
        },
    }).then((res: any) => {
        return true
    }).catch((err) => {
        if (err?.message) {
            console.log(err.response.data.message)
            // alert(err.response.data.message)
        }
        return false
    })
}

export const getOneLeadData = (id) => {
    return async (dispatch) => {
        return axios.get(`${API_URL}/lead_details/${id}`, {
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("skyoffsite_token")}`,
            },
        }).then((res: any) => {
            dispatch({
                type: "LEAD_DATA",
                data: res.data.data
            })
            return res.data.data;
        }).catch((err) => {
            if (err?.message) {
                console.log(err.response.data.message)
                // alert(err.response.data.message)
            }
            return false
        })

    }
}

export const setLeadWorkChange = (payload, id) => {
    // return async (dispatch) => {
    return axios.post(`${API_URL}/lead/work/${id}`, payload, {
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("skyoffsite_token")}`,
        },
    }).then((res: any) => {
        return res.data.message;
    }).catch((err) => {
        if (err?.message) {
            console.log(err.response.data.message)
            // alert(err.response.data.message)
        }
        return false
    })

    // }
}

export const getProductList = () => {
    return async (dispatch) => {
        return axios.get(`${API_URL}/getAllProductList`, {
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("skyoffsite_token")}`,
            },
        }).then((res: any) => {
            dispatch({
                type: "PRODUCT_LIST",
                data: res.data.data
            })
            return true;
        }).catch((err) => {
            if (err?.message) {
                console.log(err.response.data.message)
                // alert(err.response.data.message)
            }
            return false
        })
    }

}

export const setLeadAssign = (payload) => {
    return axios.post(`${API_URL}/lead/assign`, payload, {
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("skyoffsite_token")}`,
        },
    }).then((res: any) => {
        return res.data.message;
    }).catch((err) => {
        if (err?.message) {
            console.log(err.response.data.message)
            // alert(err.response.data.message)
        }
        return false
    })
}

export const setLeadVerfied = (payload) => {
    return axios.post(`${API_URL}/lead/assign/verified`, payload, {
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("skyoffsite_token")}`,
        },
    }).then((res: any) => {
        return res.data.message;
    }).catch((err) => {
        if (err?.message) {
            console.log(err.response.data.message)
            // alert(err.response.data.message)
        }
        return false
    })
}

export const setLeadGenerated = (payload) => {
    return axios.post(`${API_URL}/lead/assign/generated`, payload, {
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("skyoffsite_token")}`,
        },
    }).then((res: any) => {
        return res.data.message;
    }).catch((err) => {
        if (err?.message) {
            console.log(err.response.data.message)
            // alert(err.response.data.message)
        }
        return false
    })
}

export const insertAssignOrders = (payload) => {
    return axios.post(`${API_URL}/lead/assign`, payload, {
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("skyoffsite_token")}`,
        },
    }).then((res: any) => {
        return true;
    }).catch((err) => {
        if (err?.message) {
            console.log(err.response.data.message)
            // alert(err.response.data.message)
        }
        return false
    })
}

export const doneAllLeadsQuery = async () => {
    return axios.post(`${API_URL}/lead/orders/done`, {}, {
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("skyoffsite_token")}`,
        },
    }).then((res: any) => {
        return true;
    }).catch((err) => {
        if (err?.message) {
            console.log(err.response.data.message)
            // alert(err.response.data.message)
        }
        return false
    })
}

export const editAssignOrders = (payload, assign_id) => {
    return axios.put(`${API_URL}/lead/assign/${assign_id}`, payload, {
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("skyoffsite_token")}`,
        },
    }).then((res: any) => {
        return true;
    }).catch((err) => {
        if (err?.message) {
            console.log(err.response.data.message)
            // alert(err.response.data.message)
        }
        return false
    })
}

export const deleteAssign = (assign_id) => {
    return axios.delete(`${API_URL}/lead/assign/${assign_id}`, {
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("skyoffsite_token")}`,
        },
    }).then((res: any) => {
        // alert('stop succesfully');
        return true;
    }).catch((err) => {
        if (err?.message) {
            console.log(err.response.data.message)
            // alert(err.response.data.message)
        }
        return false
    })
}

export const getAllAssign = () => {

    return async (dispatch) => {
        return axios.get(`${API_URL}/lead/assign`, {
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("skyoffsite_token")}`,
            },
        }).then((res: any) => {
            dispatch({
                type: "ASSIGN_ORDERS",
                data: res.data.data
            })
            return res.data.data;
        }).catch((err) => {
            if (err?.message) {
                console.log(err.response.data.message)
                // alert(err.response.data.message)
            }
            return false
        })
    }

}

export const startWorking = (id) => {
    return axios.get(`${API_URL}/lead/start/${id}`, {
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("skyoffsite_token")}`,
        }
    }).then((res: any) => {
        // alert("Time Log Start");
        return true;
    }).catch((err) => {
        if (err?.message) {
            console.log(err.response.data.message)
            // alert(err.response.data.message)
        }
        return false
    })
}

export const stopWorking = (id) => {
    return axios.get(`${API_URL}/lead/stop/${id}`, {
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("skyoffsite_token")}`,
        }
    }).then((res: any) => {
        alert("Time Log Stop");
        return true;
    }).catch((err) => {
        if (err?.message) {
            console.log(err.response.data.message)
            // alert(err.response.data.message)
        }
        return false
    })
}